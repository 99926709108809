
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import Head from "next/head";
import * as Sentry from "@sentry/nextjs";

import { useWindowSize } from '../utils/useWindowSize';
import { Header, HeaderBoxes, CalendarSelect, CallbackForm, Loading } from "../components/";
import { fetchPublicAPI, fetchInternalAPI } from '../api'

const MOBILE_WDITH = 576;

const Home = ({ _articles, _month, _year }) => {
  const [ articles, setArticles ] = useState(_articles);
  const [ month, setMonth ] = useState(_month);
  const [ year, setYear ] = useState(_year);
  const { width } = useWindowSize();

  if (!articles) return null;

  // LOAD NEW DATA ON CHANGE MONTH || YEAR
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    (async () => {
      let nextMonth = Date.parse(`${year}-01-${month}`).next().month().toString("MM");
      let nextYear = month === '12' ? year + 1 : year;
      //const result = await fetchPublicAPI(`articles?_sort=published_date:DESC&published_date_gte=${year}-${month}-01&published_date_lt=${nextYear}-${nextMonth}-01`);
      const result = await fetchPublicAPI({type:'article',query:{created_gte:{value:`${year}-${month}-01`}, created_lte: {value: `${nextYear}-${nextMonth}-01`}}});
      if (result.length) {
        setArticles(result)
      } else if (!articles.length) { // IF THERE IS MONTH WHERE IS 0 ARTICLES & ARTICLES WASNT SET
        let prevMonth = Date.parse(`${year}-01-${month}`).prev().month().toString("MM");
        //const result = await fetchPublicAPI(`articles?_sort=published_date:DESC&published_date_gte=${year}-${prevMonth}-01&published_date_lt=${nextYear}-${month}-01`);
        const result = await fetchPublicAPI({type:'article',query:{created_gte:{value:`${year}-${prevMonth}-01`}, created_lte: {value: `${nextYear}-${month}-01`}}});
        setArticles(result)
      }
    })()
  }, [month, year])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!width) return;
    AOS.init({
      duration: 1000,
      offset: width > MOBILE_WDITH ? 120 : 0,
      disable: 'mobile'
    });
    AOS.refresh();
  }, [width])

  const articlesSortedByPriority = articles.sort((a, b) => b.post_priority - a.post_priority);

  return (
    <>
      <Head>
          <meta property="og:title" content="Charger"></meta>
          <meta property="og:image" content="/static/images/battery-2x-pink-bg.png"></meta>
          <meta property="og:description" content="Charger magazín"></meta>
          <meta property="og:url" content={`${process.env.NEXT_PUBLIC_DRUPAL_API_URL}`}></meta>
      </Head>
      {articles.length ? (
        <>
          <Header article={articlesSortedByPriority[0]} month={month} width={width} />
          <HeaderBoxes articles={articlesSortedByPriority} width={width} />
          <CalendarSelect _month={month} _year={year} _setMonth={setMonth} _setYear={setYear} _padding={articlesSortedByPriority.length === 1} />
          <CallbackForm />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Home;

Home.getInitialProps = async (ctx) => {
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(ctx);

  var dateFrom = new Date();
  //dateFrom.setMonth(dateFrom.getMonth() - 1);
  const dateFromString = dateFrom.toString("yyyy-MM-dd");
  const month = dateFrom.toString("MM");
  const year = dateFrom.toString("yyyy");
  const result = await fetchInternalAPI({type:'article',query:{created_gte: {value:`${dateFromString}` }}});
  return { _articles: result, _month: month, _year: parseInt(year) }
}
